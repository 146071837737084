import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Tack för ditt meddelande" noIndex />
      <div className="article-container">
        <h1 className="section-title">Kontakt</h1>
        <p>Tack för ditt meddelande! Vi hör av oss så snart vi kan.</p>
      </div>
    </Layout>
  )
}

export default ContactPage
